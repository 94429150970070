import { createApp } from "vue";
import App from "./App.vue";
import store from "./store";

import "primeicons/primeicons.css";
import "primeflex/primeflex.css";
import "./assets/styles/layout.scss";

import PrimeVue from "primevue/config";
import Aura from "@primevue/themes/aura";
import router from "./router/index.js";
import ConfirmationService from "primevue/confirmationservice";
import ToastService from "primevue/toastservice";
import StyleClass from "primevue/styleclass";
import Skeleton from "primevue/skeleton";
import InputNumber from "primevue/inputnumber";
import Button from "primevue/button";
import Vue3Barcode from "vue3-barcode";
import Dialog from "primevue/dialog";
import InputText from "primevue/inputtext";
import Select from "primevue/select";
import DataTable from "primevue/datatable";
import Column from "primevue/column";
import DatePicker from "primevue/datepicker";

import VueHtmlToPaper from "vue-html-to-paper";

import axios from "axios";
import VueAxios from "vue-axios";

import JsonExcel from "vue-json-excel3";

router.beforeEach(function (to, from, next) {
  window.scrollTo(0, 0);
  next();
});

const options = {
  name: "_blank",
  specs: ["fullscreen=yes", "titlebar=yes", "scrollbars=yes"],
  styles: [`/themes/lara-light-indigo/theme.css`, "/print-style.css"],
  timeout: 1000, // default timeout before the print window appears
  autoClose: true, // if false, the window will not close after printing
  windowTitle: "print", // override the window title
};

const app = createApp(App);
app.use(VueHtmlToPaper, options);
app.directive("styleclass", StyleClass);
app.use(store);
app.use(router);
app.use(ToastService);
app.use(ConfirmationService);
app.use(VueAxios, axios);
app.component("downloadExcel", JsonExcel);
app.component("Skeleton", Skeleton);
app.component("Vue3Barcode", Vue3Barcode);
app.component("InputNumber", InputNumber);
app.component("Button", Button);
app.component("Dialog", Dialog);
app.component("InputText", InputText);
app.component("Select", Select);
app.component("DataTable", DataTable);
app.component("Column", Column);
app.component("DatePicker", DatePicker);

app.use(PrimeVue, {
  ripple: true,
  theme: {
    preset: Aura,

    inputStyle: "outlined",
    options: {
      darkModeSelector: "light",
    },
  },
});
app.mount("#app");
