<template>
  <div>
    <div class="grid formgrid not-print">
      <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
        <div class="flex-auto">
          <label for="formNumber" class="font-bold block mb-2 text-direction-right">رقم الاستمارة او اسم الطالب</label>
          <InputText id="formNumber" :useGrouping="false" placeholder="رقم الاستمارة او اسم الطالب" v-model="formNumber" class="w-full mb-5" @keyup.enter="searchData" />
        </div>
      </div>
    </div>

    <div id="printMe" v-if="showFields">
      <div style="text-align: center">
        <img :src="require('@/assets/logo2.png')" width="300" height="150" />
        <h3>جامعة التراث</h3>
        <p>نحافظ على القيم ونعد للمستقبل</p>
        <p>استمارة اجرائات التقديم والقبول الالكترونية للعام الدراسي 2024-2025</p>
      </div>
      <div style="text-align: center">
        <Vue3Barcode value="vue3-barcode" :height="30" width="1" display-value="false" font-size="16" />
        <p>رقم الاستمارة : {{ studentData.applicationNumber }}</p>
      </div>

      <div style="height: 20px"></div>
      <table style="padding: 10px">
        <thead>
          <tr>
            <th colspan="4">المعلومات الشخصية والدراسية للطالب</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>الاسم الرباعي</td>
            <td>{{ studentData.studentName }}</td>
            <td>الرقم الاحصائي</td>
            <td>{{ studentData.collegeNumber }}</td>
          </tr>
          <tr>
            <td>رقم الهاتف</td>
            <td style="direction: ltr">{{ studentData.studentPhone }}</td>
            <td>رقم الهاتف الثاني</td>
            <td style="direction: ltr">{{ studentData.relativePhoneNumber }}</td>
          </tr>
          <tr>
            <td>الجنس</td>
            <td>{{ studentData.gender }}</td>
            <td>التولد</td>
            <td>{{ studentData.dob }}</td>
          </tr>
          <tr>
            <td>الرقم الامتحاني</td>
            <td>{{ studentData.examNumber }}</td>
            <td>الرقم السري</td>
            <td>{{ studentData.password }}</td>
          </tr>
          <tr>
            <td>التربية</td>
            <td>{{ studentData.education }}</td>
            <td>اسم المدرسة</td>
            <td>{{ studentData.schoolName }}</td>
          </tr>
          <tr>
            <td>الفرع</td>
            <td>{{ $store.state.categories.find((cat) => cat.idCategory == studentData.categoryId).categoryName }}</td>
            <td>القسم المختار</td>
            <td>{{ studentData.sectionName }}</td>
          </tr>
        </tbody>
      </table>

      <div style="height: 20px"></div>
      <table>
        <thead>
          <tr>
            <th colspan="4">معلومات خاصة بلجنة القبول</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>قناة القبول</td>
            <td>{{ studentData.acceptedTypeId }}</td>
            <td>الملاحظات</td>
            <td>{{ studentData.notes }}</td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="center" v-if="showFields">
      <Button type="button" label="طباعة" severity="help" icon="pi pi-print" @click="print()" />
    </div>
  </div>
</template>

<script>
export default {
  data: () => ({
    showFields: false,
    formNumber: null,
    studentData: null,
  }),
  methods: {
    async print() {
      await this.$htmlToPaper("printMe");
    },
    searchData() {
      let self = this;
      self.showFields = false;
      self.$http
        .get(`${self.$store.state.apiUrlApplication}/applicationSearch/${self.formNumber}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log("here");
          self.studentData = res.data;
          // self.studentData.categoryId = self.$store.state.categories.find((cat) => cat.idCategory == res.data.categoryId);
          console.log(res.data);
          self.showFields = true;
        })
        .catch((err) => {
          this.$toast.add({ severity: "error", summary: "خطأ", detail: "رقم الاستمارة او اسم الطالب غير موجود الرجاء التأكد من المعلومات", group: "tl", life: 3000 });
          self.showFields = false;
          console.log(err);
        });
    },
  },
};
</script>

<style lang="scss" scoped>
table {
  border-spacing: 1;
  border-collapse: collapse;
  background: white;
  border-radius: 6px;
  overflow: hidden;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
table * {
  position: relative;
}

table th {
  color: #ffffff;
}

table td,
table th {
  padding-right: 8px;
}
table thead tr {
  height: 60px;
  background: #3c79af;
  font-size: 16px;
}
table tbody tr {
  height: 48px;
  border-bottom: 1px solid #3c79af;
}
// table tbody tr:last-child {
//   border: 0;
// }
table td {
  text-align: right;
}
table td.l,
table th.l {
  text-align: right;
}
table td.c,
table th.c {
  text-align: center;
}
table td.r,
table th.r {
  text-align: center;
}

@media screen and (max-width: 35.5em) {
  table {
    display: block;
  }
  table > *,
  table tr,
  table td,
  table th {
    display: block;
  }
  table thead {
    display: none;
  }
  table tbody tr {
    height: auto;
    padding: 8px 0;
  }
  table tbody tr td {
    padding-left: 45%;
    margin-bottom: 12px;
  }
  table tbody tr td:last-child {
    margin-bottom: 0;
  }
  table tbody tr td:before {
    position: absolute;
    font-weight: 700;
    width: 40%;
    left: 10px;
    top: 0;
  }
  table tbody tr td:nth-child(1):before {
    content: "Code";
  }
  table tbody tr td:nth-child(2):before {
    content: "Stock";
  }
  table tbody tr td:nth-child(3):before {
    content: "Cap";
  }
  table tbody tr td:nth-child(4):before {
    content: "Inch";
  }
  table tbody tr td:nth-child(5):before {
    content: "Box Type";
  }
}
</style>
