<template>
  <div style="margin-top: 30px">
    <div class="card mx-6">
      <div class="flex justify-content-right">
        <Button label="بحث" icon="pi pi-search" @click="visible = true" />
        <div style="margin: 0 auto"></div>

        <download-excel v-if="items.length > 0" :data="items" type="xlsx" worksheet="تقرير الطلبة" name="تقرير الطلبة.xlsx" :rtl="true" :fields="json_fields"
          ><Button label="تصدير" icon="pi pi-file-excel" severity="help"
        /></download-excel>
      </div>

      <div style="height: 30px"></div>

      <DataTable :value="items" showGridlines tableStyle="min-width: 50rem" :loading="loading">
        <Column field="applicationNumber" header="رقم الاستمارة"></Column>
        <Column field="studentName" header="اسم الطالب"></Column>
        <Column field="collegeNumber" header="الرقم الجامعي"></Column>
        <Column field="acceptedTypeId" header="قناة القبول"></Column>
        <Column field="sectionName" header="القسم المختار"></Column>
        <Column field="gender" header="الجنس"></Column>
      </DataTable>
    </div>
  </div>

  <Dialog v-model:visible="visible" header="بحث في الطلبة" :style="{ width: '55rem' }" position="top" :modal="true" :draggable="false" style="direction: rtl">
    <div class="flex-auto">
      <label for="section" class="font-bold block mb-2 text-direction-right">القسم المختار</label>
      <Select
        id="section"
        v-model="sectionName"
        checkmark
        showClear
        :options="$store.state.sections"
        optionLabel="sectionName"
        optionValue="sectionName"
        placeholder="القسم المختار"
        class="w-full md:w-56 mb-5"
      />
    </div>

    <div class="flex-auto">
      <label for="acceptedId" class="font-bold block mb-2 text-direction-right">قناة القبول</label>
      <Select
        id="acceptedId"
        v-model="acceptedTypeId"
        checkmark
        showClear
        optionValue="acceptedName"
        :options="$store.state.acceptedTypes"
        optionLabel="acceptedName"
        placeholder="قناة القبول"
        class="w-full md:w-56 mb-5"
      />
    </div>

    <div class="flex items-center gap-4 mb-8">
      <div class="flex-auto">
        <label for="acceptedId" class="font-bold block mb-2 text-direction-right">تاريخ اضافة الطلبة</label>
        <DatePicker v-model="dates" showWeek selectionMode="range" style="direction: ltr" dateFormat="dd/mm/yy" />
      </div>
    </div>

    <div class="flex justify-end gap-2">
      <Button type="button" label="بحث" @click="searchStudent" :loading="loading"></Button>
    </div>
  </Dialog>
</template>

<script>
export default {
  data: () => ({
    visible: false,
    sectionName: null,
    acceptedTypeId: null,
    loading: false,
    items: [],
    dates: null,
    json_fields: {
      "اسم الطالب": "studentName",
      "الرقم الجامعي": "collegeNumber",
      "الرقم الامتحاني": "examNumber",
      المواليد: "dob",
      "قناة القبول": "acceptedTypeId",
      "جنس الطالب": "gender",
      القسم: "sectionName",
      "اسم المدرسة": "schoolName",
      الملاحظات: "notes",
      التربية: "education",
      "رقم الهاتف": "studentPhone",
      "رقم الهاتف الثاني": "relativePhoneNumber",
    },
  }),
  methods: {
    convertToMySQLDate(inputDate) {
      // Split the input date by '/' to extract day, month, and year
      const [month, day, year] = inputDate.split("/").map(Number);

      // Ensure day and month are two digits by adding a leading zero if necessary
      const formattedDay = day < 10 ? `0${day}` : day;
      const formattedMonth = month < 10 ? `0${month}` : month;

      // Combine the parts into the MySQL date format
      const mysqlDate = `${year}-${formattedMonth}-${formattedDay}`;

      return mysqlDate;
    },
    exportExcel() {},
    searchStudent() {
      let self = this;
      self.loading = true;

      let query = "1=1";
      if (self.sectionName) {
        query += `&sectionName=${self.sectionName}`;
      }
      if (self.acceptedTypeId) {
        query += `&acceptedTypeId=${self.acceptedTypeId}`;
      }

      if (self.dates != null) {
        let firstDate = new Date(self.dates[0]).toLocaleDateString("en-US", { year: "numeric", month: "numeric", day: "numeric" });
        let secondDate = new Date(self.dates[1]).toLocaleDateString("en-US", { year: "numeric", month: "numeric", day: "numeric" });
        let allowDates = [self.convertToMySQLDate(firstDate), self.convertToMySQLDate(secondDate)];
        query += `&dates=${JSON.stringify(allowDates)}`;
      }

      self.$http
        .get(`${self.$store.state.apiUrlApplication}/v2/applicationReportSearch?${query}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          self.loading = false;
          self.items = res.data;
        })
        .catch((err) => {
          console.log(err);
          self.loading = false;
        });
      self.visible = false;
    },
  },
};
</script>

<style lang="scss" scoped></style>
