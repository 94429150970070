<template>
  <div style="height: 30px"></div>
  <div class="card mx-6">
    <Tabs value="0">
      <TabList class="teb-list-edit">
        <Tab value="0" class="text-info">
          <i class="pi pi-plus-circle" />
          <span style="width: 5px; display: inline-block"></span>
          استمارة جديدة
        </Tab>
        <Tab value="1">
          <i class="pi pi-pen-to-square" />
          <span style="width: 5px; display: inline-block"></span>
          تعديل الاستمارة
        </Tab>
        <Tab value="2">
          <i class="pi pi-search" />
          <span style="width: 5px; display: inline-block"></span>
          بحث
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel value="0">
          <p class="m-0">
            <AddStudent />
          </p>
        </TabPanel>
        <TabPanel value="1">
          <EditApplication />
        </TabPanel>
        <TabPanel value="2">
          <SearchApplication />
        </TabPanel>
      </TabPanels>
    </Tabs>
  </div>
</template>

<script>
import Tabs from "primevue/tabs";
import TabList from "primevue/tablist";
import Tab from "primevue/tab";
import TabPanels from "primevue/tabpanels";
import TabPanel from "primevue/tabpanel";
import AddStudent from "./../components/applications/AddStudent.vue";
import EditApplication from "./../components/applications/EditApplication.vue";
import SearchApplication from "./../components/applications/SearchApplication.vue";
export default {
  components: {
    Tabs,
    TabList,
    Tab,
    TabPanels,
    TabPanel,
    AddStudent,
    EditApplication,
    SearchApplication,
  },
  data: () => ({
    items: [
      { route: "/add", label: "Transactions", icon: "pi pi-chart-line" },
      { route: "/edit", label: "Products", icon: "pi pi-list" },
      { route: "/search", label: "Messages", icon: "pi pi-inbox" },
    ],
  }),
};
</script>

<style lang="scss" scoped>
.p-tablist {
  align-self: center;
}
.teb-list-edit {
  box-shadow: rgba(100, 100, 111, 0.2) 0px 0px 5px 0px;
  width: 100%;
}

.p-tab-active {
  color: #1683ef;
}
</style>
