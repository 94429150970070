<template>
  <div style="height: 30px"></div>
  <div v-if="$store.state.stats != null">
    <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">مجموع الطلبة</span>
                <div class="text-900 font-medium text-xl">{{ $store.state.stats[1][0].allStudents }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-users text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
            <span class="text-500">since last visit</span> -->
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-3">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">مجموع الذكور</span>
                <div class="text-900 font-medium text-xl">{{ $store.state.stats[2][1].studentsGender }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-mars text-orange-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">%52+ </span>
            <span class="text-500">since last week</span> -->
          </div>
        </div>
        <div class="col-12 md:col-6 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">مجموع الاناث</span>
                <div class="text-900 font-medium text-xl">{{ $store.state.stats[2][0].studentsGender }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-venus text-cyan-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">520 </span>
            <span class="text-500">newly registered</span> -->
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">الطلبة من بغداد</span>
                <div class="text-900 font-medium text-xl">{{ $store.state.stats[4].length }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-orange-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-user text-orange-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">520 </span>
            <span class="text-500">newly registered</span> -->
          </div>
        </div>

        <div class="col-12 md:col-6 lg:col-2">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">الطلبة من المحافظات</span>
                <div class="text-900 font-medium text-xl">{{ $store.state.stats[5].length }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-user text-cyan-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">520 </span>
            <span class="text-500">newly registered</span> -->
          </div>
        </div>
      </div>
    </div>

    <div class="grid p-fluid px-4 py-5 md:px-6 lg:px-8" v-if="$store.state.showChart">
      <div class="col-12 lg:col-6">
        <div class="card flex flex-column align-items-center">
          <h5 class="align-self-start">Pie Chart</h5>
          <Chart type="pie" :data="$store.state.pieData" :options="pieOptions" style="width: 50%" />
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div class="card">
          <h5>Bar Chart</h5>
          <Chart type="bar" :data="$store.state.barData" :options="barOptions" />
        </div>
      </div>
    </div>

    <div class="surface-ground px-4 py-5 md:px-6 lg:px-8">
      <div class="grid">
        <div class="col-12 md:col-6 lg:col-3" v-for="(section, index) in $store.state.stats[3]" :key="index">
          <div class="surface-card shadow-2 p-3 border-round">
            <div class="flex justify-content-between mb-3">
              <div>
                <span class="block text-500 font-medium mb-3">{{ section.sectionName }}</span>
                <div class="text-900 font-medium text-xl">{{ section.studentsSection }}</div>
              </div>
              <div class="flex align-items-center justify-content-center bg-blue-100 border-round" style="width: 2.5rem; height: 2.5rem">
                <i class="pi pi-book text-blue-500 text-xl"></i>
              </div>
            </div>
            <!-- <span class="text-green-500 font-medium">24 new </span>
            <span class="text-500">since last visit</span> -->
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "primevue/chart";

export default {
  components: {
    Chart,
  },
  data: () => ({
    lineData: {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "First Dataset",
          data: [65, 59, 80, 81, 56, 55, 40],
          fill: false,
          backgroundColor: "#2f4860",
          borderColor: "#2f4860",
          tension: 0.4,
        },
        {
          label: "Second Dataset",
          data: [28, 48, 40, 19, 86, 27, 90],
          fill: false,
          backgroundColor: "#00bb7e",
          borderColor: "#00bb7e",
          tension: 0.4,
        },
      ],
    },
    pieData: {
      labels: null,
      datasets: [
        {
          data: null,
          backgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
          hoverBackgroundColor: ["#FF6384", "#36A2EB", "#FFCE56"],
        },
      ],
    },
    polarData: {
      datasets: [
        {
          data: [11, 16, 7, 3, 14],
          backgroundColor: ["#FF6384", "#4BC0C0", "#FFCE56", "#E7E9ED", "#36A2EB"],
          label: "My dataset",
        },
      ],
      labels: ["Red", "Green", "Yellow", "Grey", "Blue"],
    },
    barData: {
      labels: ["January", "February", "March", "April", "May", "June", "July"],
      datasets: [
        {
          label: "اعداد الطلبة في الاقسام",
          backgroundColor: "#2f4860",
          data: [65, 59, 80, 81, 56, 55, 40],
        },
      ],
    },
    radarData: {
      labels: ["Eating", "Drinking", "Sleeping", "Designing", "Coding", "Cycling", "Running"],
      datasets: [
        {
          label: "My First dataset",
          backgroundColor: "rgba(179,181,198,0.2)",
          borderColor: "rgba(179,181,198,1)",
          pointBackgroundColor: "rgba(179,181,198,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(179,181,198,1)",
          data: [65, 59, 90, 81, 56, 55, 40],
        },
        {
          label: "My Second dataset",
          backgroundColor: "rgba(255,99,132,0.2)",
          borderColor: "rgba(255,99,132,1)",
          pointBackgroundColor: "rgba(255,99,132,1)",
          pointBorderColor: "#fff",
          pointHoverBackgroundColor: "#fff",
          pointHoverBorderColor: "rgba(255,99,132,1)",
          data: [28, 48, 40, 19, 96, 27, 100],
        },
      ],
    },
    lineOptions: null,
    pieOptions: {
      plugins: {
        legend: {
          display: false,
        },
      },
    },
    polarOptions: null,
    barOptions: null,
    radarOptions: null,
  }),
};
</script>

<style lang="scss" scoped></style>
