<template>
  <div :key="keyComponent">
    <div class="grid formgrid">
      <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
        <div class="flex-auto">
          <label for="formNumber" class="font-bold block mb-2 text-direction-right">رقم الاستمارة او اسم الطالب</label>
          <InputText id="formNumber" :useGrouping="false" placeholder="رقم الاستمارة او اسم الطالب" v-model="formNumber" class="w-full mb-5" @keyup.enter="searchData" />
        </div>
      </div>
    </div>
    <div v-if="showFields">
      <div class="grid formgrid">
        <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="numberForm" class="font-bold block mb-2 text-direction-right">الرقم الجامعي</label>
            <InputText id="numberForm" type="text" placeholder="الرقم الجامعي" class="w-full mb-5" disabled :value="studentData.collegeNumber" />
          </div>

          <div class="flex-auto">
            <label for="phone" class="font-bold block mb-2 text-direction-right">رقم الهاتف</label>
            <InputMask id="phone" v-model="studentData.studentPhone" mask="(99999)999999" placeholder="(99999)999999" fluid class="phone-number-mask mb-5" />
          </div>

          <div class="flex-auto">
            <label for="secretNumber" class="font-bold block mb-2 text-direction-right">الرقم الامتحاني</label>
            <InputText id="secretNumber" type="text" v-model="studentData.examNumber" placeholder="الرقم الامتحاني" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="acceptedId" class="font-bold block mb-2 text-direction-right">قناة القبول</label>
            <Select
              id="acceptedId"
              v-model="studentData.acceptedTypeId"
              checkmark
              @change="changeValue"
              :options="$store.state.acceptedTypes"
              optionLabel="acceptedName"
              optionValue="acceptedName"
              placeholder="قناة القبول"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="edu" class="font-bold block mb-2 text-direction-right">التربية</label>
            <Select
              id="edu"
              @change="changeValue"
              v-model="studentData.education"
              checkmark
              :options="$store.state.educations"
              optionLabel="name"
              optionValue="name"
              placeholder="التربية"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="section" class="font-bold block mb-2 text-direction-right">القسم المختار</label>
            <Select
              id="section"
              v-model="studentData.sectionName"
              checkmark
              @change="changeValue"
              :options="$store.state.sections"
              optionLabel="sectionName"
              optionValue="sectionName"
              placeholder="القسم المختار"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="school" class="font-bold block mb-2 text-direction-right">اسم المدرسة</label>
            <InputText id="school" type="text" v-model="studentData.schoolName" placeholder="اسم المدرسة" class="w-full mb-5" />
          </div>
        </div>
        <!--  -->
        <div class="col-12 mb-2 lg:col-6 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="name" class="font-bold block mb-2 text-direction-right">اسم الطالب</label>
            <InputText id="name" type="text" v-model="studentData.studentName" placeholder="اسم الطالب" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="phone2" class="font-bold block mb-2 text-direction-right">رقم الهاتف الثاني</label>
            <InputMask id="phone2" v-model="studentData.relativePhoneNumber" mask="(99999)999999" placeholder="(99999)999999" fluid class="phone-number-mask mb-5" />
          </div>

          <div class="flex-auto">
            <label for="secretNumber" class="font-bold block mb-2 text-direction-right">الرقم السري</label>
            <InputText id="secretNumber" type="text" v-model="studentData.password" placeholder="الرقم السري" class="w-full mb-5" />
          </div>

          <div class="flex-auto">
            <label for="dob" class="font-bold block mb-2 text-direction-right">تاريخ الميلاد</label>
            <InputMask id="dob" v-model="studentData.dob" placeholder="99/99/9999" mask="99/99/9999" slotChar="dd/mm/yyyy" class="w-full mb-5 phone-number-mask" />
          </div>

          <div class="flex-auto">
            <label for="cat" class="font-bold block mb-2 text-direction-right">الفرع الدراسي</label>
            <Select
              id="cat"
              v-model="studentData.categoryId"
              checkmark
              @change="changeValue"
              :options="$store.state.categories"
              optionLabel="categoryName"
              optionValue="idCategory"
              placeholder="الفرع الدراسي"
              class="w-full md:w-56 mb-5"
            />
          </div>

          <div class="flex-auto">
            <label for="gender" class="font-bold block mb-2 text-direction-right">الجنس</label>
            <Select
              id="gender"
              @change="changeValue"
              v-model="studentData.gender"
              checkmark
              :options="$store.state.genders"
              optionValue="name"
              optionLabel="name"
              placeholder="الجنس"
              class="w-full md:w-56 mb-5"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-if="showFields">
      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <div class="flex-auto">
            <label for="cat" class="font-bold block mb-2 text-direction-right">الملاحظات</label>
            <Textarea v-model="studentData.notes" rows="5" cols="30" class="w-full md:w-56 mb-5" />
          </div>
        </div>
      </div>

      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <Divider align="center" type="solid">
            <b>رفع الملفات</b>
          </Divider>
        </div>
      </div>

      <div class="grid formgrid">
        <div class="col-12 mb-6 lg:col-6 lg:mb-0 px-5">
          <Button type="button" label="الهوية الشخصية المرفوعة مسبقا" severity="help" icon="pi pi-file-pdf" @click="showFirstFile()" />
          <div class="card">
            <Toast />
            <FileUpload
              name="attachment"
              :showCancelButton="false"
              :url="`${$store.state.apiUrlApplication}/upload`"
              @upload="onAdvancedUpload($event)"
              @error="onError($event)"
              accept="application/pdf"
              :maxFileSize="5000000"
              chooseLabel="اختيار الملف"
              uploadLabel="رفع الملف"
            >
              <template #empty>
                <h5>رفع الهوية الشخصية للطالب على ان تكون بصيغة PDF</h5>
              </template>
            </FileUpload>
          </div>
        </div>

        <div class="col-12 mb-6 lg:col-6 lg:mb-0 px-5">
          <Button type="button" label="تأييد الدرجات المرفوع مسبقا" severity="help" icon="pi pi-file-pdf" @click="showSecondFile()" />
          <div class="card">
            <Toast />
            <FileUpload
              name="attachment"
              :showCancelButton="false"
              :url="`${$store.state.apiUrlApplication}/upload`"
              @upload="onAdvancedUploadSecond($event)"
              @error="onError($event)"
              accept="application/pdf"
              :maxFileSize="5000000"
              chooseLabel="اختيار الملف"
              uploadLabel="رفع الملف"
            >
              <template #empty>
                <h5>رفع تأييد الدرجات على ان تكون بصيغة PDF</h5>
              </template>
            </FileUpload>
          </div>
        </div>
      </div>
      <div class="grid formgrid">
        <div class="col-12 mb-12 lg:col-12 lg:mb-0 px-5">
          <Divider align="center" type="solid"> </Divider>
        </div>
      </div>

      <div class="justify-center">
        <Button type="button" label="تعديل" icon="pi pi-check" :loading="loading" @click="editStudent()" />
      </div>
    </div>
  </div>
  <Toast position="top-center" group="tl" />
</template>

<script>
import InputMask from "primevue/inputmask";
import Toast from "primevue/toast";
import AutoComplete from "primevue/autocomplete";
import Textarea from "primevue/textarea";
import Divider from "primevue/divider";
import FileUpload from "primevue/fileupload";

export default {
  components: {
    Toast,
    AutoComplete,
    InputMask,
    Textarea,
    Divider,
    FileUpload,
  },
  data: () => ({
    showFields: false,
    loading: false,
    uploadFile: "none",
    uploadSecondFile: "none",
    formNumber: null,
    keyComponent: 0,
  }),

  methods: {
    onAdvancedUpload(event) {
      let self = this;
      console.log(JSON.parse(event.xhr.response).imagePath);
      self.studentData.filePath = JSON.parse(event.xhr.response).imagePath;
      this.$toast.add({ severity: "success", summary: "رفع الملف", detail: "تم رفع الملف بنجاح", life: 3000, group: "tl" });
    },
    onAdvancedUploadSecond(event) {
      let self = this;
      console.log(JSON.parse(event.xhr.response).imagePath);
      self.studentData.secondFile = JSON.parse(event.xhr.response).imagePath;
      this.$toast.add({ severity: "success", summary: "رفع الملف", detail: "تم رفع الملف بنجاح", life: 3000, group: "tl" });
    },
    onError(event) {
      console.log(event);
      this.$toast.add({ severity: "error", summary: "خطأ", detail: "حدث خطأ اثناء رفع الملف", life: 3000, group: "tl" });
    },
    showSecondFile() {
      let self = this;
      window.open(`${self.$store.state.apiUrlApplication}/${self.studentData.secondFile}`, "_blank");
    },
    showFirstFile() {
      let self = this;
      window.open(`${self.$store.state.apiUrlApplication}/${self.studentData.filePath}`, "_blank");
    },
    editStudent() {
      let self = this;
      console.log(self.studentData);

      self.studentData.dob =
        self.studentData.dob.split("/")[1] > 12
          ? `${self.studentData.dob.split("/")[0]}/12/${self.studentData.dob.split("/")[2]}`
          : `${self.studentData.dob.split("/")[0]}/${self.studentData.dob.split("/")[1]}/${self.studentData.dob.split("/")[2]}`;

      this.$http
        .put(`${this.$store.state.apiUrlApplication}/application/${self.studentData.idApplication}`, self.studentData, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log(res.data);
          this.$toast.add({ severity: "success", summary: "العملية تمت بنجاح", detail: " تم تعديل بيانات الطالب", group: "tl", life: 3000 });
        })
        .catch((err) => {
          this.$toast.add({ severity: "error", summary: "خطأ", detail: " حدث خطأ في البيانات", group: "tl", life: 3000 });
        });
    },
    searchData() {
      let self = this;
      self.showFields = false;
      self.$http
        .get(`${self.$store.state.apiUrlApplication}/applicationSearch/${self.formNumber}`, {
          headers: { Authorization: `Bearer ${self.$store.state.user}` },
        })
        .then((res) => {
          console.log("here");
          self.studentData = res.data;
          // self.studentData.categoryId = self.$store.state.categories.find((cat) => cat.idCategory == res.data.categoryId);
          console.log(res.data);
          self.showFields = true;
        })
        .catch((err) => {
          this.$toast.add({ severity: "error", summary: "خطأ", detail: "رقم الاستمارة او اسم الطالب غير موجود الرجاء التأكد من المعلومات", group: "tl", life: 3000 });
          self.showFields = false;
          console.log(err);
        });
    },
    changeValue() {
      console.log(this.studentData.categoryId);
      this.keyComponent += 1;
    },
  },
};
</script>

<style lang="scss" scoped>
.phone-number-mask {
  direction: ltr !important;
}

.text-direction-right {
  text-align: right !important;
}
</style>
