<template>
  <div class="flex align-items-center justify-content-center min-h-screen min-w-screen overflow-hidden">
    <div class="grid justify-content-center p-2 lg:p-0" style="min-width: 80%">
      <!-- <div class="col-12 mt-5 xl:mt-0 text-center">
        <img :src="require('@/assets/logo2.png')" alt="" width="20%" />
      </div> -->
      <div class="col-12 xl:col-6" style="border-radius: 56px; padding: 0.3rem; background: linear-gradient(180deg, var(--primary-color), rgba(33, 150, 243, 0) 30%)">
        <div class="h-full w-full m-0 py-7 px-4" style="border-radius: 53px; background: linear-gradient(180deg, var(--surface-50) 38.9%, var(--surface-0))">
          <div class="text-center mb-5">
            <img :src="require('@/assets/logo2.png')" alt="" width="50%" />
            <div class="text-900 text-3xl font-medium mb-3">برنامج استمارة القبول</div>
            <span class="text-600 font-medium">الرجاء تسجيل الدخول</span>
          </div>

          <div class="w-full md:w-10 mx-auto">
            <label for="email1" class="block text-900 text-xl font-medium mb-2" style="text-align: right">البريد الالكتروني</label>
            <InputText id="email1" v-model="email" type="text" class="w-full mb-3" placeholder="البريد الالكتروني" style="padding: 1rem" />

            <label for="password1" class="block text-900 font-medium text-xl mb-2" style="text-align: right">الرمز السري</label>
            <Password
              id="password1"
              v-model="password"
              placeholder="الرمز السري"
              :toggleMask="true"
              class="w-full mb-3"
              inputClass="w-full"
              :feedback="false"
              :inputStyle="{ padding: '15px 25px' }"
            ></Password>
            <Button type="button" label="تسجيل دخول" icon="pi pi-check" class="p-button-info w-full p-3" :loading="loading" @click="login" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import InputText from "primevue/inputtext";
import FloatLabel from "primevue/floatlabel";
import Password from "primevue/password";
import Button from "primevue/button";
import IconField from "primevue/iconfield";
import InputIcon from "primevue/inputicon";

export default {
  components: {
    InputText,
    FloatLabel,
    Password,
    Button,
    IconField,
    InputIcon,
  },
  data: () => ({
    email: null,
    password: null,
    loading: false,
    checked: false,
  }),
  created() {},
  methods: {
    load() {
      this.loading = true;
      setTimeout(() => {
        this.loading = false;
      }, 2000);
    },
    login() {
      let self = this;
      // this.$router.push("/");
      if (self.email && self.password) {
        this.loading = true;
        let credentials = {
          password: self.password,
          mail: self.email,
        };
        self.$http
          .post(`${self.$store.state.apiUrlEmployee}/login`, credentials)
          .then((res) => {
            this.loading = false;
            console.log(res);
            localStorage.setItem("employee", JSON.stringify(res.data.token));
            location.reload();
          })
          .catch((e) => {
            console.log(e);
            this.loading = false;
            // this.$toasted.error("حدث خطأ الرجاء التأكد من المعلومات");
          });
      } else {
        this.loading = false;
        // this.$toasted.error("الرجاء ادخال  اسم المستخدم وكلمة المرور");
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.pi-eye {
  transform: scale(1.6);
  margin-left: 1rem;
}

.pi-eye-slash {
  transform: scale(1.6);
  margin-left: 1rem;
}
</style>
